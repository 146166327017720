<template>
  <div class="navigation-barPosition">
    <div class="navigation-bar" :style="isIos==1?{}:{'padding-top':'44px'}">
      <div class="header fx-v-center" v-if="type == 10||type ==13">
        <img src="@/assets/list/backArrow.png" alt="" class="navigation-goback" @click="goBack"
          :style="!['sinopec','wxapp-hbgj','boe','cnpc'].includes(hlsource)?'opacity:1':'opacity:0'" />
        <!-- <div class="navigation-goback"></div> -->
        <search-destination-comp :type="type" @changeDepSearch="changeDepSearch" @changeSearch="changeSearch" @search="inputSearch"
          ref="searchDestinationCompRef">
        </search-destination-comp>
      </div>
      <div class="header fx-v-center" v-else>
        <img src="@/assets/list/backArrow.png" alt="" class="navigation-goback" @click="goBack"
          :style="!['sinopec','wxapp-hbgj','boe','cnpc'].includes(hlsource)?'opacity:1':'opacity:0'" />
        <search-destination :arrCityName="arrCityName" :type="type" :originCName="originCName" @changeSearch="changeSearch" @search="inputSearch">
        </search-destination>
      </div>
    </div>
  </div>
</template>

<script>
import searchDestination from "./searchBar/searchDestination";
import searchDestinationComp from "./searchBar/searchDestinationComp";
import env from "@/utils/env";
import { px2rem } from "../mixins";
// import nativeApi from "@/utils/nativeApi";

export default {
  mixins: [px2rem],
  props: {
    originCName: {
      type: String,
      default: "北京",
    },
    type: {
      type: Number,
      default: 0,
    },
    arrCityName: {
      type: String,
      default: "",
    },
    autoLoadSaveArrive: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    searchDestinationComp,
    searchDestination,
  },
  data() {
    return {
      isNative: env.isNative,
      allTypesObj: {
        0: "不限",
        1: "酒店",
        2: "门票",
        4: "美食",
        10: "跟团游",
        11: "酒店套餐",
      },
      hlsource: env.hlsource,
    };
  },
  computed: {
    isIos() {
      if (env.os == "ios") {
        return 1;
      } else {
        return 0;
      }
    },
  },
  methods: {
    inputSearch(content) {
      this.$emit("search", content);
    },
    goBack() {
      // if (env.isNative) {
      //   nativeApi.invoke("close").catch(() => {});
      // } else {
      // this.$router.back();
      if (!["sinopec", "wxapp-hbgj",'cnpc'].includes(this.hlsource)) {
        this.$page.back();
      } else {
        return false;
      }

      // }
    },
    changeSearch(cityData) {
      this.$emit("changeSearch", cityData);
    },
    changeDepSearch(cityData) {
      this.$emit("changeDepSearch", cityData);
    },
    refreshArrive() {
      if (this.$refs.searchDestinationCompRef) {
        this.$refs.searchDestinationCompRef.refreshArrive();
      }
    },
  },

  mounted() {
    if (this.type === 10 && this.autoLoadSaveArrive) {
      this.$nextTick(this.refreshArrive);
    }
    // console.log('oss---------',env.os)
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_vars.scss";
.navigation-barPosition {
  position: relative;
  z-index: 260;
  // padding-bottom: 6 * $px;

  // padding-top: constant(safe-area-inset-top); /* 兼容 iOS < 11.2 */
  // padding-top: env(safe-area-inset-top); /* 兼容 iOS >= 11.2 */
  height: calc(44px + constant(safe-area-inset-top));
  height: calc(44px + env(safe-area-inset-top));
  // background-color: #1799ff;
  .navigation-bar {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    padding-top: calc(6px + constant(safe-area-inset-top));
    padding-top: calc(6px + env(safe-area-inset-top));
    z-index: 260;
    padding-bottom: 6 * $px;
    // padding-top: 6 * $px;
    // height: calc(44px + constant(safe-area-inset-top));
    // height: calc(44px + env(safe-area-inset-top));
    // height: 60 * $px;
    background-color: #1799ff;

    .header {
      height: 32 * $px;
      // width: 375 * $px;
      padding-left: 16 * $px;
      .navigation-goback {
        width: 15 * $px;
        height: 24 * $px;
        // background-color: #ffffff;
        margin-right: 20 * $px;
      }
      .navigationTitle {
        width: 72 * $px;
        display: flex;
        justify-content: center;
        height: 32 * $px;
        font-size: 18 * $px;
        font-family: Helvetica;
        color: #ffffff;
        line-height: 32 * $px;
      }
    }
  }
}
</style>
