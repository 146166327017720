<template>
  <div class="search-box a-fix-search fx-row fx-c-center" :style="navStyle">
    <div class="select-tap fx-row fx-center" @click="handleChooseCity">
      <!-- <div class="icon"></div> -->
      <img src="@/assets/list/positionArrow.png" alt="" class="icon" />
      <div class="p">{{ cfcName }}</div>
      <!-- <i v-if="arrCityName==''"></i> -->
    </div>
    <i></i>
    <div class="search-input" @click="selectArrCity">
      <!-- <hl-search left-icon="null" v-model="arrCName" shape="round" background="#f7f9fc" :placeholder="placeholder" :readonly="true"
        :disabled="inputDisabled" @click="handleFocus" style="padding: 0" /> -->
      <div class="search-input-txt" :style="arrCName?'color: #262626;':'color: rgba(51,56,76,0.30);'">{{arrCName?arrCName:placeholder}}</div>
    </div>
    <city-gty-comp ref="cityGtyhRef" type="origin" title="选择出发地" @on-confirm-city="handleConfirmOriginCity" :dep='1' />
    <awesome-package-selector ref="arrGtyhRef" @on-confirm-city="handleConfirmArrCity" @search="inputSearch" v-if="type==10">
    </awesome-package-selector>
    <city-gty-comp ref="arrGtyhRef" v-if="type==13" type="destination" title="选择旅游目的地/景点" :typeOfBusiness='type'
      @on-confirm-city="handleConfirmArrCity" @search="inputSearch" :dep='0' />
  </div>
</template>

<script>
import Vue from "vue";

import { Search } from "huoli-ui";
import _ from "lodash";

Vue.use(Search);
import * as LocalData from "@/service/localData";
import cityGtyComp from "@/components/city/gty";
import awesomePackageSelector from "./awesome-package-selector.vue";
// import { setOriginCity, setCity } from "@/service/localData";
import { px2rem } from "../../mixins";

export default {
  mixins: [px2rem],
  components: {
    cityGtyComp,
    awesomePackageSelector,
  },
  props: {
    showType: {
      type: String,
      default: "read",
    },
    type: {
      type: Number,
      default: 0,
    },
    originCName: {
      type: String,
      default: "北京",
    },
    navWidth: {
      type: Number,
      default: 293,
    },
    arrCityName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      value: "",
      originCityName: "",
      arrCityNameShow: "",
      showSelct: false,

      // inputValue: "",
    };
  },
  provide() {
    return {
      searchBarInputValue: () => this.arrCityNameShow || "",
    };
  },
  computed: {
    cfcName() {
      return this.originCityName;
    },
    navStyle() {
      return { width: this.px2rem(this.navWidth) };
    },
    arrCName() {
      return this.arrCityNameShow;
    },
    placeholder() {
      let obj = {
        0: "不限",
        1: "酒店",
        2: "门票",
        4: "美食",
        5: "门票",
        10: "搜索目的地/关键词",
        11: "搜索景点、酒店名",
      };
      return obj[this.type];
    },
    inputDisabled() {
      if (!this.showSelct && this.inputValue != "") {
        return false;
      } else if (this.showSelct) {
        return true;
      }
    },
  },

  watch: {
    originCName(newVal) {
      this.originCityName = newVal;
    },
  },

  created() {
    // this.originCityName = this.originCName;
    this.originCityName = LocalData.getOriginCity().cityName;
    // console.log(this.originCityName);
    this.originCityCode = LocalData.getOriginCity().cityCode;
    this.refreshArrive();
  },

  methods: {
    handleFocus() {
      this.showSelct = true;
      if (this.showType === "read") {
        this.showSelct = true;
        this.$emit("on-city-fource");
      }
    },
    inputSearch(content) {
      // console.log("content", content)
      this.arrCityNameShow = content.value;
      if (this.type == 13) {
        this.$emit("search", content);
      } else {
        this.$emit("search", content.value);
      }
    },
    // 打开出发地城市选择
    handleChooseCity() {
      console.log("打开出发地城市选择");
      this.showSelct = false;
      this.$refs.cityGtyhRef.open();
    },
    //打开目的地城市选择
    selectArrCity() {
      console.log("打开目的地城市选择");

      this.showSelct = false;
      this.$refs.arrGtyhRef.open();
    },
    // 确定出发地城市选择
    handleConfirmOriginCity(cityData) {
      this.showSelct = false;
      this.originCityName = cityData.cityName;
      LocalData.setOriginCity({
        cityName: cityData.cityName,
        cityCode: cityData.cityCode,
      });
      console.log("getOriginCity", LocalData.getOriginCity());
      this.$emit("on-search-gty", { type: "origin" });
      this.$emit("changeDepSearch", { ...cityData });
    },
    // 确定目的地城市选择
    handleConfirmArrCity(cityData) {
      this.showSelct = false;
      LocalData.setCity({
        cityName: cityData.cityName,
        cityCode: cityData.cityCode,
      });
      this.arrCityNameShow = cityData.cityName;
      this.$emit("on-search-gty", { type: "destination" });
      this.$emit("changeSearch", { ...cityData });
    },
    refreshArrive() {
      if (this.$route.query.keyword) {
        this.arrCityNameShow = decodeURIComponent(
          _.get(this.$route, "query.keyword", "")
        );
      } else {
        this.arrCityNameShow = LocalData.getCity().default
          ? void 0
          : LocalData.getCity().cityName;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_vars.scss";

.search-box {
  // width: calc(100% - 1.8rem);
  width: 293 * $px;
  height: 32 * $px;
  background: #ffffff !important;
  backdrop-filter: blur(6px);
  border-radius: 18 * $px;
  overflow: hidden;
  border: 1px solid #ffffff;

  & > i {
    width: 2 * $px;
    height: 16 * $px;
    background: rgba(52, 56, 75, 0.1);
  }

  .select-tap {
    height: 32 * $px;
    padding: 0 12 * $px;
    .icon {
      width: 14 * $px;
      height: 16 * $px;
      // margin-left: 12 * $px;
      // opacity: 1;
      // background: #262626;
    }
    .p {
      font-size: 14 * $px;
      font-weight: 500;
      color: #262626;
      white-space: nowrap;
      // line-height: 20 * $px;
      margin-left: 4 * $px;
    }

    i {
      display: inline-block;
      width: 8 * $px;
      height: 5 * $px;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
      // transform: translate3d(0, 1px, 0);
      margin: 0 0 0 7 * $px;
      background-image: url("https://cdn.133.cn/ticket/images/hangban/vue/hltrip/icon/down-arrow@3x.png");
    }
  }
  .search-input {
    // flex: 1;
    width: 190 * $px;
    border-radius: 20 * $px;
    overflow: hidden;
    .search-input-txt {
      margin-left: 12 * $px;
      font-size: 13 * $px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #262626;
    }
    // ::v-deep .hl-search {
    //   background-color: unset !important;
    //   .hl-search__content {
    //     background-color: unset !important;
    //   }
    //   .hl-field__control {
    //     font-size: 13 * $px;
    //     cursor: not-allowed;
    //   }
    //   // .hl-icon {
    //   //   width: 15 * $px;
    //   //   height: 14 * $px;
    //   // }
    //   .hl-cell__left {
    //     display: none !important;
    //   }
    // }
  }
}
</style>
