<template>
  <hl-popup v-model="show" position="right" get-container="body" :style="{ width: '100%', height: '100%' }" :overlay-style="{ zIndex: 360 }" :overlay="false" :round="false">

    <!-- 顶部搜索框 -->
    <div class="navigation flex flex-center flex-start">
      <div class="back-button flex flex-center" @click="show = false">
        <img class="back-img" src="@/assets/list/searchBackIcon.png" />
      </div>
      <div class="search flex flex-center flex-start">
        <img v-if="isShowSearchIcon" class="search-img" src="@/assets/common/fangdajing.png" />
        <input type="search" class="search-input" placeholder="搜一搜景点/城市" v-model="keyword" @input="searchKeyword(keyword)" @change="searchKeyword(keyword)"
               @keydown.enter="handleClickRecommend({content: keyword}, 'keyword')">
      </div>
      <div class="search-button flex flex-center" @click="handleClickRecommend({content: keyword}, 'keyword')">
        <p class="search-text">搜索</p>
      </div>
    </div>

    <!-- 搜索结果列表 -->
    <div class="result-list" v-if="searchKeywordResult && searchKeywordResult.length">
      <div class="result-item flex flex-center flex-start" v-for="item, index in searchKeywordResult" :key="'result-item' + index"
           @click="handleClickRecommend(item, item.type === 1 ? 'arrive' : 'keyword')">
        <img class="result-item-icon" :src="item.icon">
        <p class="result-item-text">{{item.content}}</p>
      </div>
      <div class="safe-area"></div>
    </div>

    <!-- Placeholder -->
    <div class="placeholder flex flex-center" v-else>
      <div class="sidebar">
        <div class="sidebar-cell flex flex-center" v-for="item, index in sidebarList" :key="'sidebar-cell' + index" :style="{ background: sidebarIndex === index ? '#FFF' : '' }"
             @click="handleClickSiderbar(index)">
          <p class="sidebar-cell-text">{{item.name}}</p>
        </div>
        <div class="safe-area"></div>
      </div>
      <div class="container">
        <div class="container-top flex flex-wrap" v-for="item, index in placeholderTopList" :key="'container-top' + index">
          <div class="container-top-card flex flex-column flex-center" v-for="it, ind in item.recommendations" :key="'container-top-card' + ind" @click="handleClickRecommend(it, 'keyword')">
            <img class="container-top-card-img" :src="it.url">
            <p class="container-top-card-text">{{it.content}}</p>
          </div>
        </div>
        <template v-for="item, index in placeholderList">
          <p class="recommend-title" :key="'recommend-title' + index">{{item.title}}</p>
          <div class="recommend flex flex-wrap" :key="'recommend' + index">
            <div class="recommend-cell flex flex-center" v-for="it, ind in item.recommendations" :key="'recommend-cell' + ind" @click="handleClickRecommend(it, 'arrive')">
              <p class="recommend-cell-text">{{it.content}}</p>
            </div>
          </div>
        </template>
        <div class="safe-area"></div>
      </div>
    </div>

  </hl-popup>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import { Popup } from "huoli-ui";
import service from "../../../../service";
import * as LocalData from "@/service/localData";
import env from "@/utils/env";

Vue.use(Popup);

export default {
  name: "awesome-package-selector",
  data() {
    return {
      isNative: env.isNative,
      show: false,
      keyword: "",
      sidebarIndex: 0,
      sidebarList: [],
      placeholderTopList: [],
      placeholderList: [],
      searchKeywordResult: [],
    };
  },
  inject: ["searchBarInputValue"],
  methods: {
    init() {
      // 侧边栏
      service
        .r215070()
        .then((r) => _.get(r, "res.bd.data", []))
        .then((data) => (this.sidebarList = data))
        .then((data) => {
          console.log("r215070:", data);
          return data;
        })
        .then(this.updatePlaceholderData);
    },

    updatePlaceholderData({ code = "0" }) {
      service
        .r215071({ code })
        .then((r) => _.get(r, "res.bd.data", []))
        .then((data) => {
          console.log("r215071:", data);
          return data;
        })
        .then((data) => {
          this.placeholderTopList = data.filter(
            (item) => item.category === "1"
          );
          this.placeholderList = data.filter((item) => item.category === "2");
        });
    },

    searchKeyword: _.debounce(function (keyword) {
      service
        .r215072({
          keyword,
          arrCity: keyword ? void 0 : LocalData.getCity().cityName,
          arrCityCode: keyword ? void 0 : LocalData.getCity().cityCode,
          depCity: LocalData.getOriginCity().cityName,
          depCityCode: LocalData.getOriginCity().cityCode,
        })
        .then((r) => _.get(r, "res.bd.data", []))
        .then((data) => {
          console.log("r215072:", data);
          return data;
        })
        .then((data) => (this.searchKeywordResult = data));
    }, 300),

    handleClickSiderbar(index) {
      if (this.sidebarIndex === index) {
        return void 0;
      }
      this.sidebarIndex = index;
      this.sidebarList[index].code;
      this.updatePlaceholderData({
        code: _.get(this.sidebarList, [index, "code"], 0),
      });
    },

    open() {
      this.keyword = this.searchBarInputValue();
      if (this.keyword !== "") {
        this.searchKeyword(this.keyword);
      }
      this.show = true;
    },

    close() {
      this.show = false;
    },

    handleClickRecommend(item, type) {
      console.log("click:", item);
      if (type === "keyword") {
        this.$emit("search", { value: item.content });
        this.close();
        return void 0;
      }
      if (type === "arrive") {
        this.$emit("on-confirm-city", item);
        this.close();
        return void 0;
      }
    },
  },
  computed: {
    isShowSearchIcon() {
      if (this.isNative && env.os == "ios") {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_vars.scss";
@import "../../common.scss";

.navigation {
  box-sizing: border-box;
  padding-top: 32 * $px;
  // padding-top: calc(constant(safe-area-inset-top));
  // padding-top: calc(env(safe-area-inset-top));
  min-height: 44 * $px;
}

.safe-area {
  height: calc(50px + constant(safe-area-inset-bottom));
  height: calc(50px + env(safe-area-inset-bottom));
}

.recommend-title {
  font-size: 15 * $px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  color: #262626;
  margin-top: 28 * $px;
  margin-bottom: 8 * $px;
  margin-left: 8 * $px;
}

.recommend-cell {
  width: 76 * $px;
  height: 40 * $px;
  background: #f2f4f7;
  border-radius: 4 * $px;
  margin-left: 8 * $px;
  margin-top: 8 * $px;
}

.recommend-cell-text {
  width: 76 * $px;
  font-size: 13 * $px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: center;
  color: #262626;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.container-top-card-text {
  font-size: 13 * $px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: #262626;
  margin-top: 9 * $px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.container-top-card-img {
  width: 76 * $px;
  height: 58 * $px;
  border-radius: 8 * $px;
}

.container-top-card {
  margin-left: 8 * $px;
  width: 76 * $px;
}

.sidebar-cell-text {
  width: 76 * $px;
  font-size: 15 * $px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: #262626;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebar-cell {
  width: 115 * $px;
  height: 48 * $px;
}

.sidebar {
  width: 115 * $px;
  height: calc(100vh - (44 * (1rem / 20)) - constant(safe-area-inset-top));
  height: calc(100vh - (44 * (1rem / 20)) - env(safe-area-inset-top));
  background: #f2f4f7;
  overflow-y: scroll;
}

.sidebar::-webkit-scrollbar,
.container::-webkit-scrollbar,
.result-list::-webkit-scrollbar {
  display: none;
}

.container {
  width: 260 * $px;
  height: calc(100vh - (44 * (1rem / 20)) - constant(safe-area-inset-top));
  height: calc(100vh - (44 * (1rem / 20)) - env(safe-area-inset-top));
  overflow-y: scroll;
}

.container-top {
  width: 260 * $px;
  padding-top: 10 * $px;
}

.back-button {
  width: 44 * $px;
  height: 44 * $px;
}

.back-img {
  width: 9 * $px;
  height: 18 * $px;
}

.search {
  width: 279 * $px;
  height: 36 * $px;
  background: #f7f9fc;
  border-radius: 18 * $px;
  padding: 0 14 * $px;
  box-sizing: border-box;
}

.search-input {
  width: 230 * $px;
  border: none;
  background: #f7f9fc;
  margin-left: 9 * $px;
}

.search-button {
  width: 44 * $px;
  height: 44 * $px;
}

.search-text {
  font-size: 14 * $px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: center;
  color: #0078ff;
}

.search-img {
  width: 15 * $px;
  height: 14 * $px;
}

.hl-overlay {
  z-index: 360 !important;
}

.hl-popup {
  z-index: 362 !important;
}

.result-list {
  width: 375 * $px;
  height: calc(100vh - (44 * (1rem / 20)) - constant(safe-area-inset-top));
  height: calc(100vh - (44 * (1rem / 20)) - env(safe-area-inset-top));
  overflow-y: scroll;
}

.result-item-icon {
  width: 22 * $px;
  height: 22 * $px;
  margin-left: 20 * $px;
}

.result-item-text {
  width: 300 * $px;
  font-size: 16 * $px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 12 * $px;
}
</style>
